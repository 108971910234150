<template>
  <b-card>
    <b-img center fluid :src="company.logo" />
  </b-card>
</template>

<script>
import { BCard, BCardText, BImg, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
