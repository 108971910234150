<template>
  <b-card class="border-top text-center h-100">
    <b-img fluid :src="benefit.img_url" />

    <h4 class="card-title text-primary">{{ benefit.title }}</h4>
    <b-card-text class="paragraph">
      {{ benefit.desc }}
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    benefit: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss">
$primary: #444ce7;
.border-top {
  border-top: 6px solid $primary !important;
}
</style>
