<template>
  <b-card>
    <h4 class="card-title font-weight-bolder text-primary">
      {{ testimonial.title }}
    </h4>
    <b-card-text class="testi-desc">
      <p ref="text">
        {{ testimonial.desc }}
      </p>
    </b-card-text>

    <hr>

    <div class="d-flex ">
      <b-avatar
        :src="testimonial.avatar"
        class="mr-50"
        size="40"
      />
      <div class="ml-1">
        <h6 class="mb-0">
          {{ testimonial.name }}
        </h6>
        <small class="text-muted">{{ testimonial.position }}</small>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BImg, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    testimonial: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.testi-desc {
  height: 7em; /* Limit the container to three lines of text */
  overflow: hidden;
}

.testi-desc p {
  margin: 0; /* Remove default margins for the paragraph */
}

.testi-desc p.ellipsis {
  position: relative; /* Make the position relative to the container */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add the ellipsis */
}
</style>
