<template>
  <!-- Landing Page-->
  <div class="px-2 pt-0">
    <!-- Hero Section -->
    <section
      id="hero-section"
      class="px-1 px-md-4 py-0 pt-0 mt-1 mt-md-4 mb-0 mb-md-2"
    >
      <b-row class="my-0">
        <b-col
          lg="6"
          sm="12"
          class="order-lg-last order-sm-first"
        >
          <!-- Hero Illustration -->
          <b-img
            fluid
            center
            src="@/assets/tekov/illustrations/landing-hero-illustration.png"
          />
        </b-col>
        <b-col
          sm="12"
          lg="6"
          class="d-flex flex-column justify-content-center"
        >
          <h1 class="title font-weight-bolder mt-4 text-primary mb-2">
            Jelajahi Kelas dan Tingkatkan keahlianmu bersama kami.....
          </h1>
          <p class="subtitle ">
            Tekov, website pelatihan murah berkualitas yang disediakan secara
            daring dengan trainer professional di bidangnya serta sertifikat
            kompetensi yang diberikan di akhir sesi pelatihan
          </p>
        </b-col>
      </b-row>
    </section>

    <div class="content-wrapper">
      <!-- Course Card List -->
      <section
        v-if="courses.length"
        class="mt-4 p-4 bg-primary"
      >
        <h1 class="text-center mb-4 text-white">
          Pelatihan Yang Tersedia
        </h1>
        <VueSlickCarousel
          class="mt-2"
          v-bind="config.courses"
        >
          <course-card
            v-for="(course, i) in courses"
            :key="i"
            :course="course"
          />
        </VueSlickCarousel>
      </section>
      <section v-else>
        <b-row>
          <b-col class="d-flex justify-content-center mt-4">
            <div class="text-center mt-4">
              <b-spinner
                variant="primary"
                class="mr-1"
              />
              <p class="mt-1">
                Memuat Daftar Pelatihan
              </p>
            </div>
          </b-col>
        </b-row>
      </section>

      <section class="overlap-background" />

      <!-- Benefits List -->
      <section class="p-1 p-md-4">
        <h1 class="text-center mb-4 text-white">
          Kenapa Bootcamp Tekov Paling Tepat Buat Upskilling?
        </h1>
        <b-row>
          <b-col
            v-for="(benefit, i) in benefits"
            :key="i"
            sm="12"
            :lg="i < benefits.length - (benefits.length % 3) ? '4' : '6'"
            class="my-1"
          >
            <benefit-card :benefit="benefit" />
          </b-col>
        </b-row>
      </section>

      <!-- Company  -->
      <section class="p-1 p-md-4 ">
        <h1 class="text-center text-primary mb-4">
          Klien Kami
        </h1>
        <b-row class="justify-content-center">
          <b-col
            lg="6"
            class="justify-content-center"
          >
            <b-row class="text-center">
              <b-col lg="6">
                <company-card
                  :company="{
                    name: 'Gojek Indonesia',
                    logo: require('@/views/tekov/landingpage/assets/img/clients/smkn2magelang.png'),
                  }"
                />
              </b-col>
              <b-col lg="6">
                <company-card
                  :company="{
                    name: 'Hyundai',
                    logo: require('@/views/tekov/landingpage/assets/img/clients/smkassalafiyyah.png'),
                  }"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col lg="6">
            <b-row>
              <b-col lg="4">
                <company-card
                  :company="{
                    name: 'Hyundai',
                    logo: require('@/views/tekov/landingpage/assets/img/clients/smkn1cangkringan.png'),
                  }"
                />
              </b-col>
              <b-col lg="4">
                <company-card
                  :company="{
                    name: 'Hyundai',
                    logo: require('@/views/tekov/landingpage/assets/img/clients/smkn1bantul.png'),
                  }"
                />
              </b-col>
              <b-col lg="4">
                <company-card
                  :company="{
                    name: 'Hyundai',
                    logo: require('@/views/tekov/landingpage/assets/img/clients/smkn1sawit.png'),
                  }"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>

      <!-- Testimoni List -->
      <section class="mt-4 p-1 p-md-4 bg-primary">
        <h1 class="text-center mb-4 text-white">
          Apa yang mereka katakan tentang bootcamp ini
        </h1>

        <VueSlickCarousel v-bind="config.testimonials">
          <TestimonialCard
            v-for="(testimonial, i) in testimonials"
            :key="i"
            :testimonial="testimonial"
          />
        </VueSlickCarousel>
      </section>

      <!-- Explore -->
      <explore />

      <!-- footer -->
      <custom-footer />
    </div>
  </div>
  <!-- / Landing Page-->
</template>

<script>
/* eslint-disable global-require */
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {
  BImg, BRow, BCol, BCard, BSpinner,
} from 'bootstrap-vue'

// component
import Logo from '@/components/Logo.vue'
import store from '@/store/index'
import { reqGetTrainings } from '@/api/guest/training'
import CourseCard from './components/CourseCard.vue'
import BenefitCard from './components/BenefitCard.vue'
import TestimonialCard from './components/TestimonialCard.vue'
import CompanyCard from './components/CompanyCard.vue'
import Explore from './components/Explore.vue'
import CustomFooter from './components/Footer.vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BSpinner,

    VueSlickCarousel, // Carousel

    CourseCard,
    BenefitCard,
    TestimonialCard,
    CompanyCard,
    Explore,
    CustomFooter,
  },
  data() {
    return {
      // data
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
      config: {
        courses: {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          lazyLoad: 'progressive',
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        testimonials: {
          dots: true,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 3,
          rows: 2,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 1,
              },
            },
          ],
        },
      },
      courses: [
      ],
      benefits: [
        {
          title: 'Online Course',
          desc: 'Proses belajar dimana saja dan kapan saja bisa kamu lakukan.',
          img_url: require('@/assets/tekov/benefits/Online_Course.png'),
        },
        {
          title: 'Pelatih Sigap',
          desc:
            'Kami memberikan pelayanan yang sigap apabila kamu mengalami kendala atau keluhan dalam proses belajar.',
          img_url: require('@/assets/tekov/benefits/Pelatih_Sigap.png'),
        },
        {
          title: 'Biaya Recommended',
          desc: 'Jangan khawatir, untuk biaya pendaftaran sangat terjangkau. ',
          img_url: require('@/assets/tekov/benefits/Biaya_Recommended.png'),
        },
        {
          title: 'Tekov Expert Class',
          desc:
            'Kami menyediakan banyak sekali kelas online yang bisa kamu ikuti. Di sana, kamu bisa menyerap ilmu dari pakarnya secara langsung di berbagai bidang. Kamu juga bisa memilih kelas yang paling cocok untuk diri kamu. ',
          img_url: require('@/assets/tekov/benefits/Tekov_Expert_Class.png'),
        },
        {
          title: 'Dari Biasa Aja Jadi Bisa Apa Aja ',
          desc:
            'Belajar bersama dari nol, sampai kamu bisa dengan materi, tugas harian, kuis dan proyek akhir kami  ',
          img_url: require('@/assets/tekov/benefits/Dari_Biasa_Aja jadi_Bisa_Apa Aja.png'),
        },
      ],
      testimonials: [
        {
          name: 'Firman Khanafi ',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Keren',
          desc: 'Setelah mengikuti kelas game, saya ngerasa sangat terinspirasi dan sekarang saya jadi bisa membuat game berbasis 2D menggunakan Unity. Kelas ini ngajarin untuk ngembangin game yang kreatif dan menarik. Saya sangat merekomendasikan kelas ini pada siapa pun yang ingin belajar tentang pembuatan game. Terima kasih kepada mentor yang luar biasa dan materi yang disajikan dengan jelas dan mudah dipahami.',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/Firman Khanafi.jpg'),
        },
        {
          name: 'fattan hafidz adiyatma azira',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Sangat Terkesan',
          desc: 'Saya merasa sangat terkesan. Selain menambah wawasan saya tentang game development, kelas ini juga memberikan pengalaman yang belum pernah saya rasakan sebelumnya. Mentor yang berpengalaman dan materi yang disampaikan dengan jelas membuat saya semakin tertarik dan termotivasi untuk terus belajar. ',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/Azira Fattan.jpg'),
        },
        {
          name: 'Brillian Anggita Arrachman ',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Seru Banget',
          desc: 'Seru banget, banyak banget hal yang saya pelajari di kelas. Saya benar-benar belajar mengenai game development dengan jelas dan juga membuat saya jadi paham. Mentor nya juga sabar. Keren banget pokoknya! Kelas ini cocok buat yang mau mempelajari game development dengan cara yang menyenangkan dan efektif. Terima kasih buat pengalaman yang luar biasa ini!',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/Brillian Anggitaa.jpg'),
        },
        {
          name: 'Aqiela Abimanyu',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Senang Banget',
          desc: 'Seneng banget bisa belajar di TekOv! Di sini, Aku merasa belajar dan membuat aku paham banyak hal baru. Seru-seru dan bermanfaat banget, bikin ilmuku tambah luas. Aku benar-benar puas dengan pengalaman belajar di sini!',
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
        },
        {
          name: 'Valerian Kaleb Setiawirawan',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Saya menjadi lebih mengerti',
          desc: 'Disini saya benar-benar mengerti tantangan yang dihadapi saat menciptakan game dan bagaimana menemukan solusinya. Proses belajar ini membantu saya tau dan memberi wawasan yang mendalam tentang dunia game development. Saya merasa puas bisa mengatasi kesulitan-kesulitan tersebut dan belajar caranya melalui pengalaman ini.',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/VALERIAN KALEB.png'),
        },
        {
          name: 'Maccrea reynardo geosanna santosa ',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Sangat Bermanfaat',
          desc: 'Setelah belajar, aku ngerasa langsung ada hasilnya. Aku nggak cuma belajar teorinya aja, tapi langsung bisa ngerjain proyeknya. Bahkan, aku sampe bisa pamerin hasil kerja ku di lomba Krenova! Seru banget rasanya bisa liat karya kita dihargai sama orang lain. Pengalaman yang bener-bener ngasih dampak positif buat skill ku.',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/Maccrea.jpg'),
        },
        {
          name: 'Handika Putra Nur Ilhami',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Seru bangettt!',
          desc: 'Seru bangettt! Ga cuma dapet ilmu, tapi juga bisa ngebantu temen-temen. Rasanya keren banget bisa berbagi pengetahuan. Jadi gak cuma aku aja yang seneng, temen-temen juga pada dapet manfaatnya. Pengalaman yang bener-bener bikin excited buat ngajak temen-temen ikutan bareng!',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/handika putra nur ilhami.jpg'),
        },
        {
          name: 'Atika',
          position: 'Peserta Kelas Game Development (Batch 1)',
          title: 'Seru kelas nya',
          desc: 'Seru kelas nya,mentornya juga asik jadi ga tertekan sama materi yang diajarin.Banyak banget ilmu yang aku dapetin dari kelas pembuatan game sekarang suka ditanya guru hasil game nya seperti apa terus pembuatannya bagaimana bisa ku kasih lihat ke guru jadinya.Walaupun aku dari jurusan TKJ yang bisa dibilang ga ada sangkut pautnya sedikit tentang ini tapi akhirnya aku juga bisa belajar dari 0 sampai bisa buat game seperti ini',
          avatar: require('@/views/tekov/landingpage/assets/img/testimonials/handika putra nur ilhami.jpg'),
        },
      ],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    this.getTrainings()
  },
  methods: {
    getTrainings() {
      reqGetTrainings().then(res => {
        this.courses = res.data.data.data.map(training => ({
          id: training.id,
          title: training.title,
          desc:
              training.description,
          image: training.image,
          contact_url: 'https://wa.me/6281212345678',
          url: 'https://www.tekov.id',
          price: training.price * (100 - training.discount) / 100,
          base_price: training.price,
        }))
      })
    },
  },
}
</script>

<style>
.slick-slide {
  padding: 0 10px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/page-misc.scss';

.title {
  font-size: 2.8rem;
}

.subtitle {
  font-size: 1.2rem;
  line-height: 1.8;
}

.big-text {
  font-size: 2.5rem;
}

.height-text {
  min-height: 75px;
}

.content-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.overlap-background {
  position: absolute;
  width: 100%;
  min-height: 600px;
  height: calc(100vh);
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('~@/assets/tekov/bottom-pattern.svg');
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }
}
</style>
